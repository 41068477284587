import React, { Component } from 'react'

export default class Htaccess extends Component {
    render() {
        return (
            <div className="container" id="kt_docs_content_container">
            {/*begin::Card*/}
            <div className="card card-docs mb-2">
                {/*begin::Card Body*/}
                <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                {/*begin::Section*/}
                <div className="pb-10">
                    {/*begin::Heading*/}
                    <h1 className="anchor fw-bolder mb-2" id="overview">
                    <a href="#overview" /><img alt="" src="../@space/media/services/apache-logo.png" className="h-35px" /></h1>
                    {/*end::Heading*/}
                    {/*begin::Block*/}
                    <div className="pt-5">
                        <div class="separator my-10"></div>
                        {/*start::Content*/}
                        <h2 className="anchor fw-bolder mb-2" id="office-exchange2">
                            <a href="#office-exchange3" />1. React JS - React Router DOM on Apache
                        </h2>
                        <div className="py-5">
                            {/*begin::Highlight*/}
                            <div className="highlight">
                            <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                            <div className="highlight-code">
                                <pre className=" language-bash" tabIndex={0}><code className=" language-bash"> 
                                <span className="token builtin class-name">{'<'}IfModule mod_rewrite.c{'>'}</span>
                                <br/>
                                RewriteEngine On
                                <br/>
                                RewriteBase /
                                <br/>
                                RewriteRule ^index\.html$ - [L]
                                <br/>
                                RewriteCond %{'{REQUEST_FILENAME}'} !-f
                                <br/>
                                RewriteCond %{'{REQUEST_FILENAME}'} !-d
                                <br/>
                                RewriteCond %{'{REQUEST_FILENAME}'} !-l
                                <br/>
                                RewriteRule . /index.html [L]
                                <br/>
                                <span className="token builtin class-name">{'</IfModule>'}</span>
                                </code></pre>
                            </div>
                            </div>
                            {/*end::Highlight*/}
                        </div>
                        {/*end::Content*/}
                        {/*start::Content*/}
                        <h2 className="anchor fw-bolder mb-2" id="office-exchange2">
                            <a href="#office-exchange3" />2. CI SLL and Routing 301 - on Apache
                        </h2>
                        <div className="py-5">
                            <ol>
                                <li> Redirecting to HTTPS
                                    {/*begin::Highlight*/}
                                    <div className="highlight">
                                    <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                                    <div className="highlight-code">
                                        <pre className=" language-bash" tabIndex={0}><code className=" language-bash">
                                        RewriteEngine On
                                        <br/>
                                        RewriteCond %{'{HTTPS}'} off
                                        <br/>
                                        RewriteRule (.*) https://%{'{HTTP_HOST}'}%{'{REQUEST_URI}'} [R=301,L]
                                        <br/>
                                        RewriteCond %{'{REQUEST_FILENAME}'} !-f
                                        <br/>
                                        RewriteCond %{'{REQUEST_FILENAME}'} !-d
                                        <br/>
                                        RewriteRule ^(.*)$ index.php?/$1 [L]
                                        </code></pre>
                                    </div>
                                    </div>
                                    {/*end::Highlight*/}
                                </li>
                                <li> Redirecting to HTTPS (Without 301 redirection)
                                    {/*begin::Highlight*/}
                                    <div className="highlight">
                                    <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                                    <div className="highlight-code">
                                        <pre className=" language-bash" tabIndex={0}><code className=" language-bash">
                                        RewriteEngine On
                                        <br/>
                                        RewriteCond %{'{HTTPS}'} off
                                        <br/>
                                        RewriteRule (.*) https://%{'{HTTP_HOST}'}%{'{REQUEST_URI}'}
                                        <br/>
                                        RewriteCond %{'{REQUEST_FILENAME}'} !-f
                                        <br/>
                                        RewriteCond %{'{REQUEST_FILENAME}'} !-d
                                        <br/>
                                        RewriteRule ^(.*)$ index.php?/$1 [L]
                                        </code></pre>
                                    </div>
                                    </div>
                                    {/*end::Highlight*/}
                                </li>
                                <li> Redirecting to HTTPS (A different method)
                                    {/*begin::Highlight*/}
                                    <div className="highlight">
                                    <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                                    <div className="highlight-code">
                                        <pre className=" language-bash" tabIndex={0}><code className=" language-bash"> 
                                        RewriteEngine On
                                        <br/>
                                        RewriteCond %{'{HTTP_HOST}'} ^example\.com [NC]
                                        <br/>
                                        RewriteCond %{'{SERVER_PORT}'} 80
                                        <br/>
                                        RewriteRule ^(.*)$ https://www.kurniasafety.com/$1 [R=301,L]
                                        <br/>
                                        RewriteCond %{'{REQUEST_FILENAME}'} !-f
                                        <br/>
                                        RewriteCond %{'{REQUEST_FILENAME}'} !-d
                                        <br/>
                                        RewriteRule ^(.*)$ index.php?/$1 [L]
                                        </code></pre>
                                    </div>
                                    </div>
                                    {/*end::Highlight*/}
                                </li>
                                <li> Redirecting Non-www URLs to www
                                    {/*begin::Highlight*/}
                                    <div className="highlight">
                                    <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                                    <div className="highlight-code">
                                        <pre className=" language-bash" tabIndex={0}><code className=" language-bash"> 
                                        RewriteEngine On
                                        <br/>
                                        RewriteCond %{'{HTTP_HOST}'} !^www\. [NC]
                                        <br/>
                                        RewriteRule ^(.*)$ http://www.%{'{HTTP_HOST}'}/$1 [R=301,L]
                                        <br/>
                                        RewriteCond %{'{REQUEST_FILENAME}'} !-f
                                        <br/>
                                        RewriteCond %{'{REQUEST_FILENAME}'} !-d
                                        <br/>
                                        RewriteRule ^(.*)$ index.php?/$1 [L]
                                        </code></pre>
                                    </div>
                                    </div>
                                    {/*end::Highlight*/}
                                </li>
                                <li> Redirecting www URLs to Non-www
                                    {/*begin::Highlight*/}
                                    <div className="highlight">
                                    <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                                    <div className="highlight-code">
                                        <pre className=" language-bash" tabIndex={0}><code className=" language-bash"> 
                                        RewriteEngine On
                                        <br/>
                                        RewriteCond %{'{HTTP_HOST}'} ^www\.(.*)$ [NC]
                                        <br/>
                                        RewriteRule ^(.*)$ http://%1/$1 [R=301,L]
                                        <br/>
                                        RewriteCond %{'{REQUEST_FILENAME}'} !-f
                                        <br/>
                                        RewriteCond %{'{REQUEST_FILENAME}'} !-d
                                        <br/>
                                        RewriteRule ^(.*)$ index.php?/$1 [L]
                                        </code></pre>
                                    </div>
                                    </div>
                                    {/*end::Highlight*/}
                                </li>
                                <li> Redirecting to HTTPS and www
                                    {/*begin::Highlight*/}
                                    <div className="highlight">
                                    <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                                    <div className="highlight-code">
                                        <pre className=" language-bash" tabIndex={0}><code className=" language-bash"> 
                                        RewriteEngine On
                                        <br/>
                                        RewriteCond %{'{HTTPS}'} off
                                        <br/>
                                        RewriteRule (.*) https://%{'{HTTP_HOST}'}%{'{REQUEST_URI}'} [R=301,L]
                                        <br/>
                                        RewriteCond %{'{HTTP_HOST}'} !^www\. [NC]
                                        <br/>
                                        RewriteRule ^(.*)$ http://www.%{'{HTTP_HOST}'}/$1 [R=301,L]
                                        <br/>
                                        RewriteCond %{'{REQUEST_FILENAME}'} !-f
                                        <br/>
                                        RewriteCond %{'{REQUEST_FILENAME}'} !-d
                                        <br/>
                                        RewriteRule ^(.*)$ index.php?/$1 [L]
                                        </code></pre>
                                    </div>
                                    </div>
                                    {/*end::Highlight*/}
                                </li>
                                <li> Redirecting to HTTPS and Non-www
                                    {/*begin::Highlight*/}
                                    <div className="highlight">
                                    <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                                    <div className="highlight-code">
                                        <pre className=" language-bash" tabIndex={0}><code className=" language-bash"> 
                                        RewriteEngine On
                                        <br/>
                                        RewriteCond %{'{HTTPS}'} off
                                        <br/>
                                        RewriteRule (.*) https://%{'{HTTP_HOST}'}%{'{REQUEST_URI}'} [R=301,L]
                                        <br/>
                                        RewriteCond %{'{HTTP_HOST}'} ^www\.(.*)$ [NC]
                                        <br/>
                                        RewriteRule ^(.*)$ http://%1/$1 [R=301,L]
                                        <br/>
                                        RewriteCond %{'{REQUEST_FILENAME}'} !-f
                                        <br/>
                                        RewriteCond %{'{REQUEST_FILENAME}'} !-d
                                        <br/>
                                        RewriteRule ^(.*)$ index.php?/$1 [L]
                                        </code></pre>
                                    </div>
                                    </div>
                                    {/*end::Highlight*/}
                                </li>
                            </ol>
                        </div>
                        {/*end::Content*/}
                    </div>
                    {/*end::Block*/}
                </div>
                </div>
                {/*end::Card Body*/}
            </div>
            {/*end::Card*/}
            </div>
        )
    }
}
