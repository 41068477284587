import React, { Component } from 'react'

export default class Footer extends Component {
    render() {
        return (
            <div className="py-4 d-flex flex-lg-column py-6" id="kt_footer">
            {/*begin::Container*/}
            <div className="container d-flex flex-column flex-md-row align-items-center justify-content-between">
                {/*begin::Copyright*/}
                <div className="text-dark order-2 order-md-1">
                <span className="text-muted fw-bold me-1">©2021</span>
                <a href="#" target="_blank" className="text-gray-800 text-hover-primary">Kurnia Safety Supplies</a>
                </div>
                {/*end::Copyright*/}
            </div>
            {/*end::Container*/}
            </div>
        )
    }
}
