import React from 'react'

function Gsuite() {
    return (
        <div className="container" id="kt_docs_content_container">
        {/*begin::Card*/}
        <div className="card card-docs mb-2">
            {/*begin::Card Body*/}
            <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                {/*begin::Section*/}
                <div className="pb-10">
                    {/*begin::Heading*/}
                    <h1 className="anchor fw-bolder mb-2" id="overview">
                    <a href="#overview" /><img alt="" src="../@space/media/services/google-workspace.png" className="h-35px" />
                    &nbsp;<span className="svg-icon svg-icon-primary svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                        <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF" />
                        <path className="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white" />
                    </svg></span></h1>
                    {/*end::Heading*/}
                    {/*begin::Block*/}
                    <div className="pt-5">
                        <div class="separator my-10"></div>
                        {/*start::Content*/}
                        <h2 className="anchor fw-bolder mb-2" id="overview">
                            <a href="#overview" />1. Sign in into Gmail (Web Version)
                        </h2>
                        <div className="py-5">
                            <a href="https://accounts.google.com/AccountChooser/signinchooser?service=mail&continue=https%3A%2F%2Fmail.google.com%2Fmail%2F&flowName=GlifWebSignIn&flowEntry=AccountChooser" target="_blank" rel="noopener noreferrer" class="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary me-2 mb-2">Gmail</a>
                        </div>
                        {/*end::Content*/}
                        <div class="separator my-10"></div>
                        {/*start::Content*/}
                        <h2 className="anchor fw-bolder mb-2" id="overview">
                            <a href="#overview" />1.2 Sign in into Gmail in Desktop with any Mail Clients (Desktop Version)
                        </h2>
                        {/*start::Info*/}
                        <div class="py-5">
                            <div className="d-flex align-items-center rounded py-5 px-5 bg-light-warning border border-warning border-3 border-dashed">
                                <span className="svg-icon svg-icon-3x svg-icon-warning me-5">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={10} fill="black" />
                                    <rect x={11} y={14} width={7} height={2} rx={1} transform="rotate(-90 11 14)" fill="black" />
                                    <rect x={11} y={17} width={2} height={2} rx={1} transform="rotate(-90 11 17)" fill="black" />
                                    </svg>
                                </span>
                            <div className="text-gray-700 fw-bold fs-6">Connect your google workspace mail with any mail client eg,. outlook, thunderbird etc,.</div>
                            </div>
                        </div>
                        {/*end::Info*/}
                            <ol>
                                <li>Check if IMAP has enable, open <a href="https://www.google.com/intl/id/gmail/about/" target="_blank" rel="noopener noreferrer">Gmail</a></li>
                                <li>In the top right, click Settings Settings and then See all settings.</li>
                                <li>Click the Forwarding and POP/IMAP tab.</li>
                                <li>In the "IMAP access" section, select Enable IMAP.</li>
                                <li>Click Save Changes.</li>
                                <li>Sometime you still can't sign. To solve this you might need to disable "Less secure apps" on your Google Account</li>
                            </ol>
                            {/*begin::Table*/}
                            <div className="py-5">
                            <div className="table-responsive border rounded">
                                <table className="table table-striped table-flush align-middle mb-0">
                                {/*begin::Block*/}
                                <thead>
                                    <tr className="fs-4 fw-bolder text-dark p-6">
                                    <th className="min-w-200px">Incoming Mail Server</th>
                                    <th className="min-w-200px">Outgoing Mail Server SMTP</th>
                                    <th className="min-w-400px">Port</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="p-6">
                                        <td>
                                            <code>imap.gmail.com</code>
                                        </td>
                                        <td>
                                            <code>smtp.gmail.com</code>
                                        </td>
                                        <td>993 SSL - 465 TLS / 587 TLS/STARTTLS</td>
                                    </tr>
                                </tbody>
                                {/*end::Block*/}
                                </table>
                            </div>
                            </div>
                            {/*end::Table*/}
                    </div>
                    {/*end::Block*/}
                </div>
                {/*end::Section*/}
                <div class="separator separator-dashed border-dark my-10"></div>
                {/*begin::Section*/}
                <div className="pb-10">
                    {/*begin::Heading*/}
                    <h1 className="anchor fw-bolder mb-2" id="overview">
                    <a href="#overview" />Google Calendar&nbsp;<img alt="" src="../@space/media/services/google-calendar.png" className="h-35px" />
                    &nbsp;<span className="svg-icon svg-icon-primary svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                        <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF" />
                        <path className="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white" />
                    </svg></span></h1>
                    {/*end::Heading*/}
                    {/*begin::Block*/}
                    <div className="pt-5">
                        {/*begin::content*/}
                        <div class="separator my-10"></div>
                        <h2 className="anchor fw-bolder mb-2" id="overview">
                            <a href="#overview" />1. Export & Backup Google Calendar
                        </h2>
                        <ol>
                            <li>Sign in to your Google Gmail account, and then choose Calendar.</li>
                            <li>Choose My Calendars {">"} Settings.
                                {/*begin::Col*/}
                                <div className="col-6">
                                <img src="https://support.content.office.net/en-us/media/0ddce734-686d-4f9f-9e39-bd7f0ba40c94.png" />
                                </div>
                                {/*end::Col*/}
                            </li>
                            <li>Choose Export calendars, and save / download to your computer.
                                {/*begin::Col*/}
                                <div className="col-6">
                                <img src="https://support.content.office.net/en-us/media/8c74fee2-ed05-4d80-8450-705bb5d9e2cf.png"/>
                                </div>
                                {/*end::Col*/}
                            </li>
                            <li>Go to that location and unzip / unrar the file: right-click the file and choose Extract All.
                                {/*begin::Col*/}
                                <div className="col-6">
                                    <img src="https://support.content.office.net/en-us/media/dd6cff61-2f49-4a2a-a9ca-9050085e7196.png" />
                                </div>
                                {/*end::Col*/}
                                <span>The extracted file will look something like the this one:</span>
                                {/*begin::Col*/}
                                <div className="col-6">
                                    <img src="https://support.content.office.net/en-us/media/56c72486-4b15-472a-b27c-c10165c9d7f9.png" />
                                </div>
                                {/*end::Col*/}
                            </li>
                            <li>Now you're ready to import your Google Calendar into Any mail client.</li>
                        </ol>
                    </div>
                    {/*end::Block*/}
                </div>
                {/*end::Section*/}

                <div class="separator separator-dashed border-dark my-10"></div>
                {/*begin::Section*/}
                <div className="pb-10">
                    {/*begin::Heading*/}
                    <h1 className="anchor fw-bolder mb-2" id="overview">
                    <a href="#overview" />Google Contact&nbsp;<img alt="" src="../@space/media/services/google-contact.png" className="h-35px" />
                    &nbsp;<span className="svg-icon svg-icon-primary svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                        <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF" />
                        <path className="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white" />
                    </svg></span></h1>
                    {/*end::Heading*/}
                    {/*begin::Block*/}
                    <div className="pt-5">
                        {/*begin::content*/}
                        <div class="separator my-10"></div>
                        <h2 className="anchor fw-bolder mb-2" id="overview">
                            <a href="#overview" />1. Export Google contacts
                        </h2>
                        <div class="py-5">
                            <div className="d-flex align-items-center rounded py-5 px-5 bg-light-warning border border-warning border-3 border-dashed">
                                <span className="svg-icon svg-icon-3x svg-icon-warning me-5">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={10} fill="black" />
                                    <rect x={11} y={14} width={7} height={2} rx={1} transform="rotate(-90 11 14)" fill="black" />
                                    <rect x={11} y={17} width={2} height={2} rx={1} transform="rotate(-90 11 17)" fill="black" />
                                    </svg>
                                </span>
                            <div className="text-gray-700 fw-bold fs-6">Note: Google does not export the photos of your contacts.</div>
                            </div>
                        </div>
                        {/*end::Info*/}
                        <ol>
                            <li>From your Gmail account, choose Gmail {">"}Contacts.</li>
                            <li>Select More {">"} Export.</li>
                            <li>Choose the contact group you want to export.</li>
                            <li>Choose the export format Outlook CSV (for importing into Outlook or another application).
                                {/*begin::Col*/}
                                <div className="col-6">
                                <img src="https://support.content.office.net/en-us/media/d1130979-72db-4c18-8dcd-b3055c4cbda5.png" />
                                </div>
                                {/*end::Col*/}
                            </li>
                            <li>Choose Export.</li>
                            <li>When prompted, choose Save As, and browse to a location to save the file.</li>
                        </ol>
                    </div>
                    {/*end::Block*/}
                </div>
                {/*end::Section*/}

                <div class="separator separator-dashed border-dark my-10"></div>
                {/*begin::Section*/}
                <div className="pb-10">
                    {/*begin::Heading*/}
                    <h1 className="anchor fw-bolder mb-2" id="overview">
                    <a href="#overview" />Google Drive&nbsp;<img alt="" src="../@space/media/services/google-drive-logo.png" className="h-35px" />
                    &nbsp;<span className="svg-icon svg-icon-primary svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                        <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF" />
                        <path className="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white" />
                    </svg></span></h1>
                    {/*end::Heading*/}
                    {/*begin::Block*/}
                    <div className="pt-5">
                        {/*begin::content*/}
                        <div class="separator my-10"></div>
                        <h2 className="anchor fw-bolder mb-2" id="overview">
                            <a href="#overview" />1. Backup Everything from your Google Google Drive
                        </h2>
                        <div class="py-5">
                            <div className="d-flex align-items-center rounded py-5 px-5 bg-light-warning border border-warning border-3 border-dashed">
                                <span className="svg-icon svg-icon-3x svg-icon-warning me-5">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={10} fill="black" />
                                    <rect x={11} y={14} width={7} height={2} rx={1} transform="rotate(-90 11 14)" fill="black" />
                                    <rect x={11} y={17} width={2} height={2} rx={1} transform="rotate(-90 11 17)" fill="black" />
                                    </svg>
                                </span>
                            <div className="text-gray-700 fw-bold fs-6">In this case we will move everything inside our Google Drive to our local computer.</div>
                            </div>
                        </div>
                        {/*end::Info*/}
                        <ol>
                            <li>Sign to your Google Drive.</li>
                            <li>Download all files inside your google drive and save to your computer, the easiest way is you can create new folder to store all these.</li>
                        </ol>
                    </div>
                    {/*end::Block*/}
                </div>
                {/*end::Section*/}

            </div>
            {/*end::Card Body*/}
        </div>
        {/*end::Card*/}
        </div>
    )
}

export default Gsuite;