import React from 'react'

function Gcloud() {
    return (
        <div className="container" id="kt_docs_content_container">
        {/*begin::Card*/}
        <div className="card card-docs mb-2">
            {/*begin::Card Body*/}
            <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
            {/*begin::Section*/}
            <div className="pb-10">
                {/*begin::Heading*/}
                <h1 className="anchor fw-bolder mb-2" id="gcloud">
                <a href="#linode" /><img alt="" src="../@space/media/services/gcp.png" className="h-35px" />
                &nbsp;<span className="svg-icon svg-icon-primary svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                    <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF" />
                    <path className="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white" />
                </svg></span>
                </h1>
                {/*end::Heading*/}
                {/*begin::Block*/}
                <div className="pt-5">
                    <div class="separator my-10"></div>

                    {/*start::Content*/}
                    <h2 className="anchor fw-bolder mb-2" id="gcloud1">
                        <a href="#gcloud1" />1. Deploy App in the Google Cloud
                    </h2>
                    <div className="pb-10">
                        {/*begin::Block*/}
                        <div className="py-5">This page we shows you how to serving app in the cloud using Google Cloud Compute Engine. We providing a complete framework and best practices for setting up a website is beyond the scope of this guides. But from a high-level view, the technical side of setting up a web-serving infrastructure on Compute Engine requires that you:</div>
                        <ul>
                            <li>Understand the requirements</li>
                            <li>Plan the design and cost. Think through your architecture and write down your design. Be as explicit as you can</li>
                            <li>Create the components, that you might usually think of as physical assets, such as computers and network switches, are provided through services in Compute Engine.</li>
                            <li>Test. Verify that everything works as you expect.</li>
                            <li>Deploy to production. Open up your site for the world to see and use.</li>
                        </ul>
                        {/*end::Block*/}
                    </div>
                    {/*end::Content*/}
                    <div class="separator my-5"></div>

                    {/*start::Content*/}
                    <h2 className="anchor fw-bolder mb-2" id="gcloud2">
                        <a href="#gcloud2" />2. Creating a custom VM instance from Compute Engine
                    </h2>
                    <div className="pb-10">
                        {/*begin::Block*/}
                        <div className="py-5">Compute Engine offers predefined machine types that you can use when you create a VM instance. A predefined machine type has a preset number of vCPUs and amount of memory, and is charged at a set price. In this part we will using E2 Custom Machine with 2vCPU and 4GB RAM</div>
                        {/*end::Block*/}
                        <ol>
                            <li> Creating VM
                            {/*begin::Highlight*/}
                            <div className="highlight">
                            <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                            <div className="highlight-code">
                                <pre className=" language-bash" tabIndex={0}><code className=" language-bash">gcloud compute instances create example-instance --machine-type=e2-custom-2-4096</code></pre>
                            </div>
                            </div>
                            {/*end::Highlight*/}
                            </li>
                            <li>Reserve Static IP
                            {/*begin::Highlight*/}
                            <div className="highlight">
                            <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                            <div className="highlight-code">
                                <pre className=" language-bash" tabIndex={0}><code className=" language-bash">gcloud compute instances add-access-config [INSTANCE_NAME] --access-config-name "[ACCESS_CONFIG_NAME]" --address [IP_ADDRESS]</code></pre>
                            </div>
                            </div>
                            {/*end::Highlight*/}
                            </li>
                            <li>Create Firewall Rules.</li>
                            <li>Preparing Server in SSH. In this we have server ready so we want install control panel.
                            {/*begin::Highlight*/}
                            <div className="highlight">
                            <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                            <div className="highlight-code">
                                <pre className=" language-bash" tabIndex={0}><code className=" language-bash">yum update -y <br/>yum install -y perl <br/>yum install -y wget <br/>yum remove NetworkManager <br/>hostname sub.domain.com <br/>cd /home && curl -o latest -L https://securedownloads.cpanel.net/latest && sh latest <br/><span className="token builtin class-name">#updatehostname</span> mkdir -p /etc/dhcp/dhclient-exit-hooks.d/ && echo -ne '#!/bin/sh\nhostname cpanel.example.com\n/scripts/fixetchosts\n' {">"} /etc/dhcp/dhclient-exit-hooks.d/zzz-set-hostname.sh && chmod +x /etc/dhcp/dhclient-exit-hooks.d/zzz-set-hostname.sh</code></pre>
                            </div>
                            </div>
                            {/*end::Highlight*/}
                            </li>
                            <li>After complete, you can making any config on your control panel</li>
                        </ol>
                    </div>
                    {/*end::Content*/}
                    <div class="separator my-5"></div>

                    {/*start::Content*/}
                    <h2 className="anchor fw-bolder mb-2" id="gcloud3">
                        <a href="#gcloud3" />3. Scaling your deployment with Load balancer
                    </h2>
                    <div className="pb-10">
                        <ul>
                            <li>To determine the mode of a load balancer, run the following command:
                            {/*begin::Highlight*/}
                            <div className="highlight">
                            <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                            <div className="highlight-code">
                                <pre className=" language-bash" tabIndex={0}><code className=" language-bash">gcloud compute forwarding-rules describe FORWARDING_RULE_NAME</code></pre>
                            </div>
                            </div>
                            {/*end::Highlight*/}
                            </li>
                            <li>In this We use Load Balancer in single regional <br/>
                                <button type="button" className="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary me-2 mb-2" data-bs-toggle="modal" data-bs-target="#kt_modal_balancer">
                                    See Example
                                </button>
                                <div className="modal fade" tabIndex={-1} id="kt_modal_balancer">
                                    <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                        <h5 className="modal-title">Example</h5>
                                        {/*begin::Close*/}
                                        <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close">
                                            <span className="svg-icon svg-icon-2x" />
                                        </div>
                                        {/*end::Close*/}
                                        </div>
                                        <div className="modal-body">
                                        <img src="https://cloud.google.com/load-balancing/images/regional-ext-https-lb.svg" />
                                        </div>
                                        <div className="modal-footer">
                                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    {/*end::Content*/}
                    <div class="separator my-5"></div>

                    {/*start::Content*/}
                   <h2 className="anchor fw-bolder mb-2" id="gcloud4">
                        <a href="#gcloud4" />3.1 HTTP/2 limitations
                    </h2>
                    <div className="pb-10">
                        <div className="py-5">HTTP/2 between the load balancer and the instance can require significantly more TCP connections to the instance than HTTP(S). Connection pooling, an optimization that reduces the number of these connections with HTTP(S), is not currently available with HTTP/2.</div>
                    </div>
                    {/*end::Content*/}
                    <div class="separator my-5"></div>

                    {/*start::Content*/}
                    <h2 className="anchor fw-bolder mb-2" id="gcloud5">
                        <a href="#linode" />4. SSH Keys initialization for Maintenace & Data Portection
                    </h2>
                    <br/> 
                    <h3>Public Key Authentication on macOS And Linux.</h3>
                    {/*start::Info*/}
                    <div className="pb-10">
                        <div class="py-5">
                            {/*begin::List*/}
                            <ul className="py-0 m-0">
                                <li className="py-3">Create a new key pair.
                                <div className="py-5">
                                    <div className="d-flex align-items-center rounded py-5 px-5 bg-light-danger">
                                        <span className="svg-icon svg-icon-3x svg-icon-danger me-5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={10} fill="black" />
                                            <rect x={11} y={14} width={7} height={2} rx={1} transform="rotate(-90 11 14)" fill="black" />
                                            <rect x={11} y={17} width={2} height={2} rx={1} transform="rotate(-90 11 17)" fill="black" />
                                            </svg>
                                        </span>
                                    <div className="text-gray-700 fw-bold fs-6">Caution <br/>This command will overwrite an existing RSA key pair, potentially locking you out of other systems.<br/>If you’ve already created a key pair, skip this step. To check for existing keys, run ls ~/.ssh/id_rsa*.<br/>If you accidentally lock yourself out of the SSH service on your Linode, you can still use the Lish console to login to your server. After you’ve logged in via Lish, update your authorized_keys file to use your new public key. This should re-establish normal SSH access.</div>
                                    </div>
                                    <br/>
                                    {/*begin::Highlight*/}
                                    <div className="highlight">
                                    <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                                    <div className="highlight-code">
                                        <pre className=" language-bash" tabIndex={0}><code className=" language-bash">ssh-keygen -b 4096</code></pre>
                                    </div>
                                    </div>
                                    {/*end::Highlight*/}
                                </div>
                                </li>
                                <li className="py-3">Upload Public Key .
                                <div className="py-5">
                                    {/*begin::Highlight*/}
                                    <div className="highlight">
                                    <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                                    <div className="highlight-code">
                                        <pre className=" language-bash" tabIndex={0}><code className=" language-bash">ssh-copy-id your_username@IPCLOUDSERVER</code></pre>
                                    </div>
                                    </div>
                                    {/*end::Highlight*/}
                                </div>
                                </li>
                            </ul>
                            {/*end::List*/}

                        </div>
                    </div>
                    {/*end::Info*/}
                    {/*end::Content*/}

                </div>
                {/*end::Block*/}
            </div>
            </div>
            {/*end::Card Body*/}
        </div>
        {/*end::Card*/}
        </div>
    )
}

export default Gcloud;