import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Header extends Component {
    render() {
        return (
            <div id="kt_docs_header" className="docs-header align-items-stretch mb-2 mb-lg-10">
                {/*begin::Container*/}
                <div className="container">
                    <div className="d-flex align-items-stretch justify-content-between py-3 h-75px">
                    {/*begin::Aside toggle*/}
                    <div className="d-flex align-items-center d-lg-none ms-n2 me-1" title="Show aside menu">
                        <div className="btn btn-icon btn-flex btn-active-color-primary" id="kt_docs_aside_toggle">
                        {/*begin::Svg Icon | path: icons/duotune/abstract/abs015.svg*/}
                        <span className="svg-icon svg-icon-1 mt-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                            <path d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z" fill="black" />
                            <path opacity="0.3" d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z" fill="black" />
                            </svg>
                        </span>
                        {/*end::Svg Icon*/}
                        </div>
                    </div>
                    {/*end::Aside toggle*/}
                    {/*begin::Logo*/}
                    <div className="d-flex d-lg-none align-items-center flex-grow-1 flex-lg-grow-0 me-3 me-lg-15">
                        <img alt="logo" src="../@space/kss-logo.png" className="h-35px" />
                    </div>
                    {/*end::Logo*/}
                    {/*begin::Wrapper*/}
                    <div className="d-flex align-items-center justify-content-between flex-lg-grow-1">
                        {/*begin::Header title*/}
                        <div className="d-flex align-items-center" id="kt_docs_header_title">
                        {/*begin::Page title*/}
                        <div className="docs-page-title d-flex flex-column flex-lg-row align-items-lg-center py-5 mb-lg-0" data-kt-swapper="true" data-kt-swapper-mode="prepend" data-kt-swapper-parent="{default: '#kt_docs_content_container', 'lg': '#kt_docs_header_title'}">
                            {/*begin::Title*/}
                            <h1 className="d-flex align-items-center text-dark my-1 fs-4">Documentation</h1>
                            {/*end::Title*/}
                            {/*begin::Separator*/}
                            <span className="d-none d-lg-block bullet h-20px w-1px bg-secondary mx-4" />
                            {/*end::Separator*/}
                        </div>
                        {/*end::Page title*/}
                        </div>
                        {/*end::Header title*/}
                        {/*begin::Toolbar*/}
                        <div className="d-flex align-items-center">
                            <Link to='/' className="btn btn-flex btn-bg-white btn-color-gray-700 btn-active-primary h-40px border-0 fw-bolder px-3 px-lg-6 me-2 me-lg-4">Homepage</Link>
                        </div>
                        {/*end::Toolbar*/}
                    </div>
                    {/*end::Wrapper*/}
                    </div>
                    <div className="border-gray-300 border-bottom border-bottom-dashed" />
                </div>
                {/*end::Container*/}
            </div>
        )
    }
}
