import React, { Component } from 'react'

export default class Office365 extends Component {
    render() {
        return (
            <div className="container" id="kt_docs_content_container">
                {/*begin::Card*/}
                <div className="card card-docs mb-2">
                    {/*begin::Card Body*/}
                    <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                    {/*begin::Section*/}
                    <div className="pb-10">
                        {/*begin::Heading*/}
                        <h1 className="anchor fw-bolder mb-2" id="overview">
                        <a href="#overview" /><img alt="" src="../@space/media/services/office365.png" className="h-35px" />&nbsp;
                        <span className="svg-icon svg-icon-primary svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                            <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF" />
                            <path className="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white" />
                        </svg></span>
                        </h1>
                        {/*end::Heading*/}
                        {/*begin::Block*/}
                        <div className="py-5">
                        {/*start::Info*/}
                        <div class="py-5">
                            <div className="d-flex align-items-center rounded py-5 px-5 bg-light-primary border border-primary border-3 border-dashed">
                                <span className="svg-icon svg-icon-3x svg-icon-primary me-5"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                    <path opacity="0.3" d="M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z" fill="black" />
                                    <rect x={6} y={12} width={7} height={2} rx={1} fill="black" />
                                    <rect x={6} y={7} width={12} height={2} rx={1} fill="black" />
                                </svg></span>
                            <div className="text-gray-700 fw-bold fs-6">The migration is completed</div>
                            </div>
                        </div>
                        {/*end::Info*/}
                        {/*start::Info*/}
                        <div class="py-5">
                            <div className="d-flex align-items-center rounded py-5 px-5 bg-light-success border border-success border-3 border-dashed">
                                <span className="svg-icon svg-icon-3x svg-icon-success me-5"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                    <path opacity="0.3" d="M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z" fill="black" />
                                    <rect x={6} y={12} width={7} height={2} rx={1} fill="black" />
                                    <rect x={6} y={7} width={12} height={2} rx={1} fill="black" />
                                </svg></span>
                            <div className="text-gray-700 fw-bold fs-6">Application in Office 365 : Word, Excel, Power Point, Form, Calendar, Contact, Outlook, Exchange, OneDrive, Share Point & Team</div>
                            </div>
                        </div>
                        {/*end::Info*/}
                        {/*start::Info*/}
                        <div class="py-5">
                            <div className="d-flex align-items-center rounded py-5 px-5 bg-light-warning border border-warning border-3 border-dashed">
                                <span className="svg-icon svg-icon-3x svg-icon-warning me-5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path opacity="0.3" d="M12 22C13.6569 22 15 20.6569 15 19C15 17.3431 13.6569 16 12 16C10.3431 16 9 17.3431 9 19C9 20.6569 10.3431 22 12 22Z" fill="black"></path>
                                    <path d="M19 15V18C19 18.6 18.6 19 18 19H6C5.4 19 5 18.6 5 18V15C6.1 15 7 14.1 7 13V10C7 7.6 8.7 5.6 11 5.1V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V5.1C15.3 5.6 17 7.6 17 10V13C17 14.1 17.9 15 19 15ZM11 10C11 9.4 11.4 9 12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7C10.3 7 9 8.3 9 10C9 10.6 9.4 11 10 11C10.6 11 11 10.6 11 10Z" fill="black"></path>
                                </svg>
                                </span>
                            <div className="text-gray-700 fw-bold fs-6">Important note! The migration synching from the oldest and latest email (Thursday 30/12/2021 15:00 WIB) from your Google Workspace (formerly G Suite) mail server. The incoming emails will continue synching to your Microsoft inbox also during that time. There is a slight delay on emails that you receive on December 31/12/2021.</div>
                            </div>
                        </div>
                        {/*end::Info*/}
                        {/*start::Info*/}
                        <div class="py-5">
                            <div className="d-flex align-items-center rounded py-5 px-5 bg-light-warning border border-warning border-3 border-dashed">
                                <span className="svg-icon svg-icon-3x svg-icon-warning me-5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path opacity="0.3" d="M12 22C13.6569 22 15 20.6569 15 19C15 17.3431 13.6569 16 12 16C10.3431 16 9 17.3431 9 19C9 20.6569 10.3431 22 12 22Z" fill="black"></path>
                                    <path d="M19 15V18C19 18.6 18.6 19 18 19H6C5.4 19 5 18.6 5 18V15C6.1 15 7 14.1 7 13V10C7 7.6 8.7 5.6 11 5.1V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V5.1C15.3 5.6 17 7.6 17 10V13C17 14.1 17.9 15 19 15ZM11 10C11 9.4 11.4 9 12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7C10.3 7 9 8.3 9 10C9 10.6 9.4 11 10 11C10.6 11 11 10.6 11 10Z" fill="black"></path>
                                </svg>
                                </span>
                                <div className="text-gray-700 fw-bold fs-6">
                                    You were not able to access your Google Workspace related service (Mail, Calendar, & Contact) using *@kurniasafety.com again. Started from 31/12/2021.
                                </div>
                            </div>
                        </div>
                        {/*end::Info*/}
                        {/*start::Info*/}
                        <div class="py-5">
                            <div className="d-flex align-items-center rounded py-5 px-5 bg-light-warning border border-warning border-3 border-dashed">
                                <span className="svg-icon svg-icon-3x svg-icon-warning me-5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path opacity="0.3" d="M12 22C13.6569 22 15 20.6569 15 19C15 17.3431 13.6569 16 12 16C10.3431 16 9 17.3431 9 19C9 20.6569 10.3431 22 12 22Z" fill="black"></path>
                                    <path d="M19 15V18C19 18.6 18.6 19 18 19H6C5.4 19 5 18.6 5 18V15C6.1 15 7 14.1 7 13V10C7 7.6 8.7 5.6 11 5.1V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V5.1C15.3 5.6 17 7.6 17 10V13C17 14.1 17.9 15 19 15ZM11 10C11 9.4 11.4 9 12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7C10.3 7 9 8.3 9 10C9 10.6 9.4 11 10 11C10.6 11 11 10.6 11 10Z" fill="black"></path>
                                </svg>
                                </span>
                            <div className="text-gray-700 fw-bold fs-6">Every user that login with desktop or other mail client (outlook, thunderbird,.etc) must sign out and relogin after 31/12/2021. </div>
                            </div>
                        </div>
                        {/*end::Info*/}
                        </div>
                        {/*end::Block*/}

                        {/*begin::Block*/}
                        <div className="pt-5">
                            <div class="separator my-10"></div>
                            {/*start::Content*/}
                            <h2 className="anchor fw-bolder mb-2" id="office">
                                <a href="#office" />1. Sign in into Office 365 & Outlook Mail (Web Version)
                            </h2>
                            <div className="py-5">
                                <a href="http://office.kurniasafety.com" target="_blank" rel="noopener noreferrer" class="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary me-2 mb-2">Office 365</a>
                                <a href="http://outlook.kurniasafety.com" target="_blank" rel="noopener noreferrer" class="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary me-2 mb-2">Outlook 365</a>
                            </div>
                            {/*start::Info*/}
                            <div class="py-5">
                                <div className="d-flex align-items-center rounded py-5 px-5 bg-light-warning border border-warning border-3 border-dashed">
                                    <span className="svg-icon svg-icon-3x svg-icon-warning me-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={10} fill="black" />
                                        <rect x={11} y={14} width={7} height={2} rx={1} transform="rotate(-90 11 14)" fill="black" />
                                        <rect x={11} y={17} width={2} height={2} rx={1} transform="rotate(-90 11 17)" fill="black" />
                                        </svg>
                                    </span>
                                <div className="text-gray-700 fw-bold fs-6">Some user credentials has been changed. You can ask your manager for Email and Password.</div>
                                </div>
                            </div>
                            <div class="py-5">
                                <div className="d-flex align-items-center rounded py-5 px-5 bg-light-warning border border-warning border-3 border-dashed">
                                    <span className="svg-icon svg-icon-3x svg-icon-warning me-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={10} fill="black" />
                                        <rect x={11} y={14} width={7} height={2} rx={1} transform="rotate(-90 11 14)" fill="black" />
                                        <rect x={11} y={17} width={2} height={2} rx={1} transform="rotate(-90 11 17)" fill="black" />
                                        </svg>
                                    </span>
                                    <div className="text-gray-700 fw-bold fs-6">Incase you have proviously sign up with microsoft account. Please select account that created by <strong>IT Department</strong>. </div><img src="../@space/media/authemail.png" className="h-500px" />
                                </div>
                            </div>
                            {/*end::Info*/}
                            {/*end::Content*/}
                            <div class="separator my-10"></div>
                            
                            {/*start::Content*/}
                            <h2 className="anchor fw-bolder mb-2" id="office-login">
                                <a href="#office-login" />1.2 Sign in into Outlook Mail (Desktop Version)
                            </h2>
                            <div className="py-5">
                                <p>Before you download make sure you don't have outlook installed. If you already have outlook you can skip this step</p>
                                <a href="https://www.office.com/" class="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary me-2 mb-2">Download</a>
                            </div>
                            {/*end::Content*/}
                            {/*start::Content*/}
                            <h2 className="anchor fw-bolder mb-2" id="office-login">
                                <a href="#office-login" />1.2.1 Sign in into Outlook Mail (Mobile Version)
                            </h2>
                            <div className="py-5">
                                <p>We have office 365 and Microsoft related service and there's no advanced configurations to sign in into Outlook App. Just fill your name, email and password.</p>
                                <a href="https://apps.apple.com/id/app/microsoft-outlook/id951937596" class="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary me-2 mb-2">iOS</a>
                                <a href="https://play.google.com/store/apps/details?id=com.microsoft.office.outlook&hl=en&gl=US" class="btn btn-outline btn-outline-dashed btn-outline-primary btn-active-light-primary me-2 mb-2">Android</a>
                            </div>
                            {/*end::Content*/}

                            {/*start::Content*/}
                            <h2 className="anchor fw-bolder mb-2" id="office-login1">
                                <a href="#office-login1" />2.1. Desktop Login for *Non Existing Account.
                            </h2>
                            {/*start::Info*/}
                            <div class="py-5">
                                <div className="d-flex align-items-center rounded py-5 px-5 bg-light-warning border border-warning border-3 border-dashed">
                                    <span className="svg-icon svg-icon-3x svg-icon-warning me-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={10} fill="black" />
                                        <rect x={11} y={14} width={7} height={2} rx={1} transform="rotate(-90 11 14)" fill="black" />
                                        <rect x={11} y={17} width={2} height={2} rx={1} transform="rotate(-90 11 17)" fill="black" />
                                        </svg>
                                    </span>
                                <div className="text-gray-700 fw-bold fs-6">Only for Outlook Mail with Non Existing Account. Your username is your full email address.</div>
                                </div>
                            </div>
                            {/*end::Info*/}
                            <ol>
                                <li>Open Outlook and select File {">"} Add Account.
                                    {/*begin::Col*/}
                                    <div className="col-6 lg-3">
                                        <img src="https://support.content.office.net/en-us/media/990377e4-91bc-43b6-926c-a5c5b2e4e602.png" />
                                    </div>
                                    {/*end::Col*/}
                                    {/*start::Info*/}
                                    <div class="py-5">
                                        <div className="d-flex align-items-center rounded py-5 px-5 bg-light-mute border border-mute border-3 border-dashed">
                                            <span className="svg-icon svg-icon-3x svg-icon-mute me-5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={10} fill="black" />
                                                <rect x={11} y={14} width={7} height={2} rx={1} transform="rotate(-90 11 14)" fill="black" />
                                                <rect x={11} y={17} width={2} height={2} rx={1} transform="rotate(-90 11 17)" fill="black" />
                                                </svg>
                                            </span>
                                        <div className="text-gray-700 fw-bold fs-6">Outlook 2007 users should select Tools {">"} Account Settings. On the Email tab, select New.</div>
                                        </div>
                                    </div>
                                    {/*end::Info*/}
                                </li>
                                <li>For Outlook 2016, enter your email address, then select Connect. For Outlook 2013 and Outlook 2010, select Email Account and then enter your name, email address, and password. Then select Next
                                    {/*begin::Col*/}
                                    <div className="col-6">
                                        <img src="https://support.content.office.net/en-us/media/5e463acb-c18a-4a64-92d5-51154a47a71e.png" />
                                    </div>
                                    {/*end::Col*/}
                                    {/*start::Info*/}
                                    <div class="py-5">
                                        <div className="d-flex align-items-center rounded py-5 px-5 bg-light-mute border border-mute border-3 border-dashed">
                                            <span className="svg-icon svg-icon-3x svg-icon-mute me-5">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                                <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={10} fill="black" />
                                                <rect x={11} y={14} width={7} height={2} rx={1} transform="rotate(-90 11 14)" fill="black" />
                                                <rect x={11} y={17} width={2} height={2} rx={1} transform="rotate(-90 11 17)" fill="black" />
                                                </svg>
                                            </span>
                                        <div className="text-gray-700 fw-bold fs-6">Note: If Outlook doesn't accept your password, you may have two-factor authentication enabled for your Outlook.com account.</div>
                                        </div>
                                    </div>
                                    {/*end::Info*/}
                                </li>
                                <li>If prompted, enter your password again, then select OK, and Finish to start using Outlook.</li>
                            </ol>
                            {/*end::Content*/}

                            {/*start::Content*/}
                            <h2 className="anchor fw-bolder mb-2" id="office-login2">
                                <a href="#office-login2" />2.2. Desktop Login *Existing Account
                            </h2>
                            {/*start::Info*/}
                            <div class="py-5">
                                <div className="d-flex align-items-center rounded py-5 px-5 bg-light-warning border border-warning border-3 border-dashed">
                                    <span className="svg-icon svg-icon-3x svg-icon-warning me-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={10} fill="black" />
                                        <rect x={11} y={14} width={7} height={2} rx={1} transform="rotate(-90 11 14)" fill="black" />
                                        <rect x={11} y={17} width={2} height={2} rx={1} transform="rotate(-90 11 17)" fill="black" />
                                        </svg>
                                    </span>
                                <div className="text-gray-700 fw-bold fs-6">Only for Outlook Mail with Existing Account</div>
                                </div>
                            </div>
                            {/*end::Info*/}
                            <div className="py-5">
                                <ol>
                                    <li>Open outlook</li>
                                    <li>Find option, select account</li>
                                    <li>Delete/Remove your existing account</li>
                                    <li>Re add with your email and password</li>
                                    <li>Fill *your email</li>
                                    <li>Fill *your password</li>
                                    <li>Finish</li>
                                </ol>
                            </div>
                            {/*end::Content*/}

                            {/*start::Content*/}
                            <h2 className="anchor fw-bolder mb-2" id="office-login3">
                                <a href="#office-login3" />2.2.1 Desktop Login with *Advanced SMTP 
                            </h2>
                            {/*start::Info*/}
                            <div class="py-5">
                                <div className="d-flex align-items-center rounded py-5 px-5 bg-light-warning border border-warning border-3 border-dashed">
                                    <span className="svg-icon svg-icon-3x svg-icon-warning me-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={10} fill="black" />
                                        <rect x={11} y={14} width={7} height={2} rx={1} transform="rotate(-90 11 14)" fill="black" />
                                        <rect x={11} y={17} width={2} height={2} rx={1} transform="rotate(-90 11 17)" fill="black" />
                                        </svg>
                                    </span>
                                <div className="text-gray-700 fw-bold fs-6">Sign to outlook with advanced SMTP</div>
                                </div>
                            </div>
                            {/*end::Info*/}
                                <ol>
                                    <li>Click more setting to add port and select advanced</li>
                                    <li>Incoming port 995 SSL/TLS</li>
                                    <li>Outgoing port 587 STARTTLS</li>
                                    <li>Long Time "1"</li>
                                    <li>Check purge item</li>
                                </ol>
                                {/*begin::Table*/}
                                <div className="py-5">
                                <div className="table-responsive border rounded">
                                    <table className="table table-striped table-flush align-middle mb-0">
                                    {/*begin::Block*/}
                                    <thead>
                                        <tr className="fs-4 fw-bolder text-dark p-6">
                                        <th className="min-w-200px">Incoming Mail Server</th>
                                        <th className="min-w-200px">Outgoing Mail Server SMTP</th>
                                        <th className="min-w-400px">Port</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="p-6">
                                            <td>
                                                <code>outlook.office365.com</code>
                                            </td>
                                            <td>
                                                <code>outlook.office365.com</code>
                                            </td>
                                            <td>995 SSL/TLS - 587 STARTTLS</td>
                                        </tr>
                                    </tbody>
                                    {/*end::Block*/}
                                    </table>
                                </div>
                                </div>
                                {/*end::Table*/}
                        </div>
                        {/*end::Block*/}
                    </div>

                    <div class="separator separator-dashed border-dark my-10"></div>
                    {/*begin::Section*/}
                    <div className="pb-10">
                        {/*begin::Heading*/}
                        <h1 className="anchor fw-bolder mb-2" id="overview">
                        <a href="#overview" />Outlook Contact&nbsp;<img alt="" src="../@space/media/services/outlook-logo.png" className="h-35px" />
                        &nbsp;<span className="svg-icon svg-icon-primary svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                            <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF" />
                            <path className="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white" />
                        </svg></span></h1>
                        {/*end::Heading*/}
                        {/*begin::Block*/}
                        <div className="pt-5">
                            {/*begin::content*/}
                            <div class="separator my-10"></div>
                            <h2 className="anchor fw-bolder mb-2" id="overview">
                                <a href="#overview" />1. Import Contact from any service eg,. Google Contacts to Outlook.
                            </h2>
                            {/*start::Info*/}
                            <div class="py-5">
                                <div className="d-flex align-items-center rounded py-5 px-5 bg-light-warning border border-warning border-3 border-dashed">
                                    <span className="svg-icon svg-icon-3x svg-icon-warning me-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={10} fill="black" />
                                        <rect x={11} y={14} width={7} height={2} rx={1} transform="rotate(-90 11 14)" fill="black" />
                                        <rect x={11} y={17} width={2} height={2} rx={1} transform="rotate(-90 11 17)" fill="black" />
                                        </svg>
                                    </span>
                                <div className="text-gray-700 fw-bold fs-6">Important note you need to do this step for each Google Calendar you want to import to Outlook.</div>
                                </div>
                            </div>
                            {/*end::Info*/}
                            <ol>
                                <li>Choose File {">"} Open & Export {">"} Import/Export.
                                    {/*begin::Col*/}
                                    <div className="col-6">
                                        <img src="https://support.content.office.net/en-us/media/e28d47a0-9394-4586-9766-3bd8bd9a2279.png" />
                                        </div>
                                    {/*end::Col*/}
                                </li>
                                <li>Choose Import from another program or file {">"} Next.</li>
                                <li>Choose Comma Separated Values {">"} Next.</li>
                                <li>In the Import a File box, browse to choose the .csv file you saved your Gmail contacts to.
                                    {/*begin::Col*/}
                                    <div className="col-6">
                                    <img src="https://support.content.office.net/en-us/media/c619ad8a-867c-4ce2-bcc6-174a7876367a.png" />
                                    </div>
                                    {/*end::Col*/}
                                </li>
                                <li>Choose how you want Outlook to handle duplicate contacts {">"} Next .</li>
                                <li>In the Select a destination folder box, scroll to the top if needed and select the Contacts folder {">"} Next.</li>
                                <li>Choose Finish.</li>
                            </ol>
                            {/*begin::content*/}
                            <div class="separator my-10"></div>
                            <h2 className="anchor fw-bolder mb-2" id="overview">
                                <a href="#overview" />1.3 View your contacts in Outlook
                            </h2>
                            <ol>
                                <li>In Outlook, select Address Book, choose the name of your mailbox, and choose Contacts.
                                    {/*begin::Col*/}
                                    <div className="col-6">
                                        <img src="https://support.content.office.net/en-us/media/2e885274-aaeb-481b-bd49-59ae87e9909c.png" />
                                        </div>
                                    {/*end::Col*/}
                                </li>
                                <li>You'll see your list of contacts in the Address Book.
                                    {/*begin::Col*/}
                                    <div className="col-6">
                                        <img src="https://support.content.office.net/en-us/media/5e463acb-c18a-4a64-92d5-51154a47a71e.png" />
                                        </div>
                                    {/*end::Col*/}
                                </li>
                            </ol>

                        </div>
                        {/*end::Block*/}
                    </div>
                    {/*end::Section*/}

                    <div class="separator separator-dashed border-dark my-10"></div>
                    {/*begin::Section*/}
                    <div className="pb-10">
                        {/*begin::Heading*/}
                        <h1 className="anchor fw-bolder mb-2" id="overview">
                        <a href="#overview" />Outlook Calendar&nbsp;<img alt="" src="../@space/media/services/outlook-logo.png" className="h-35px" />
                        &nbsp;<span className="svg-icon svg-icon-primary svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                            <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF" />
                            <path className="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white" />
                        </svg></span></h1>
                        {/*end::Heading*/}
                        {/*begin::Block*/}
                        <div className="pt-5">
                        {/*begin::content*/}
                        <div class="separator my-10"></div>
                            <h2 className="anchor fw-bolder mb-2" id="overview">
                                <a href="#overview" />1. Import Calendar from any service eg,. Google Calendar to Outlook.
                            </h2>
                            {/*start::Info*/}
                            <div class="py-5">
                                <div className="d-flex align-items-center rounded py-5 px-5 bg-light-warning border border-warning border-3 border-dashed">
                                    <span className="svg-icon svg-icon-3x svg-icon-warning me-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={10} fill="black" />
                                        <rect x={11} y={14} width={7} height={2} rx={1} transform="rotate(-90 11 14)" fill="black" />
                                        <rect x={11} y={17} width={2} height={2} rx={1} transform="rotate(-90 11 17)" fill="black" />
                                        </svg>
                                    </span>
                                <div className="text-gray-700 fw-bold fs-6">Important note you need to do this step for each Google Calendar you want to import to Outlook.</div>
                                </div>
                            </div>
                            {/*end::Info*/}
                            <ol>
                                <li>In Outlook on your desktop, go to your Calendar.</li>
                                <li>Choose File {">"} Open & Export {">"} Import/Export</li>
                                <li>In the Import and Export Wizard, choose Import an iCalendar (.ics) or vCalendar file, and then choose Next.
                                    {/*begin::Col*/}
                                    <div className="col-6">
                                    <img src="https://support.content.office.net/en-us/media/d2079193-f04b-43f5-a8d8-e8a3c42217b7.png" />
                                    </div>
                                    {/*end::Col*/}
                                </li>
                                <li>Browse to where you stored the extracted file, and choose the one that ends with gmail.com, and choose OK.
                                    {/*begin::Col*/}
                                    <div className="col-6">
                                    <img src="https://support.content.office.net/en-us/media/7af89534-1849-4d99-87fb-038270f77428.png"/>
                                    </div>
                                    {/*end::Col*/}
                                </li>
                                <li>Choose Import.
                                    {/*begin::Col*/}
                                    <div className="col-6">
                                        <img src="https://support.content.office.net/en-us/media/254cbfe5-3a8d-448e-9162-c6b6b1d1119d.png" />
                                    </div>
                                    {/*end::Col*/}
                                </li>
                                <li>Go to your Outlook calendar. You should now see your Google Calendar events there.</li>
                            </ol>
                        </div>
                        {/*end::Block*/}
                    </div>
                    {/*end::Section*/}

                    <div class="separator separator-dashed border-dark my-10"></div>
                        {/*begin::Section*/}
                        <div className="pb-10">
                            {/*begin::Heading*/}
                            <h1 className="anchor fw-bolder mb-2" id="overview">
                            <a href="#overview" />One Drive&nbsp;<img alt="" src="../@space/media/services/one-drive.png" className="h-35px" />
                            &nbsp;<span className="svg-icon svg-icon-primary svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                                <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF" />
                                <path className="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white" />
                            </svg></span></h1>
                            {/*end::Heading*/}
                            {/*begin::Block*/}
                            <div className="pt-5">
                                {/*begin::content*/}
                                <div class="separator my-10"></div>
                                <h2 className="anchor fw-bolder mb-2" id="overview">
                                    <a href="#overview" />1. Upload file from your computer to One Drive
                                </h2>
                                {/*start::Info*/}
                                <div class="py-5">
                                    <div className="d-flex align-items-center rounded py-5 px-5 bg-light-success border border-success border-3 border-dashed">
                                        <span className="svg-icon svg-icon-3x svg-icon-success me-5">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                            <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={10} fill="black" />
                                            <rect x={11} y={14} width={7} height={2} rx={1} transform="rotate(-90 11 14)" fill="black" />
                                            <rect x={11} y={17} width={2} height={2} rx={1} transform="rotate(-90 11 17)" fill="black" />
                                            </svg>
                                        </span>
                                    <div className="text-gray-700 fw-bold fs-6">Note: For transferring large files or lots of files, we recommend adding the files to the OneDrive desktop app rather than using the Upload button on the website..</div>
                                    </div>
                                </div>
                                {/*end::Info*/}
                                {/*start::Info*/}
                                <div class="py-5">
                                    <div className="d-flex align-items-center rounded py-5 px-5 bg-light-primary border border-primary border-3 border-dashed">
                                        <span className="svg-icon svg-icon-3x svg-icon-primary me-5"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.3" d="M20 3H4C2.89543 3 2 3.89543 2 5V16C2 17.1046 2.89543 18 4 18H4.5C5.05228 18 5.5 18.4477 5.5 19V21.5052C5.5 22.1441 6.21212 22.5253 6.74376 22.1708L11.4885 19.0077C12.4741 18.3506 13.6321 18 14.8167 18H20C21.1046 18 22 17.1046 22 16V5C22 3.89543 21.1046 3 20 3Z" fill="black" />
                                            <rect x={6} y={12} width={7} height={2} rx={1} fill="black" />
                                            <rect x={6} y={7} width={12} height={2} rx={1} fill="black" />
                                        </svg></span>
                                    <div className="text-gray-700 fw-bold fs-6">You can also drag files from File Explorer or Mac Finder straight to your files on the OneDrive website.</div>
                                    </div>
                                </div>
                                {/*end::Info*/}
                                <ol>
                                    <li>Sign in with your Microsoft account, and then browse to the location where you want to add the files.</li>
                                    <li>Select Upload</li>
                                    <li>Select the files you want to upload, and then select Open.</li>
                                </ol>
                            </div>
                            {/*end::Block*/}
                        </div>
                        {/*end::Section*/}

                    <div class="separator separator-dashed border-dark my-10"></div>
                    {/*new::section*/}
                    <div className="pb-10">
                        {/*begin::Heading*/}
                        <h1 className="anchor fw-bolder mb-2" id="office-exchange">
                        <a href="#office-exchange" /><img alt="" src="../@space/media/services/ms-exchange.png" className="h-35px" />
                        &nbsp;<span className="svg-icon svg-icon-primary svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                        <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF" />
                        <path className="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white" />
                        </svg></span>
                        <span class="badge badge-light-primary">Task has been marked as complete</span>
                        </h1>
                        {/*end::Heading*/}
                        {/*begin::Block*/}
                        <div className="pt-5">
                            <div class="separator my-10"></div>
                            {/*start::Content*/}
                            <h2 className="anchor fw-bolder mb-2" id="office-exchange1">
                                <a href="#office-exchange1" />1. Migration from Google Workspace mailboxes using Exchange
                            </h2>
                            {/*start::Info*/}
                            <div class="py-5">
                                <div className="d-flex align-items-center rounded py-5 px-5 bg-light-warning border border-warning border-3 border-dashed">
                                    <span className="svg-icon svg-icon-3x svg-icon-warning me-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={10} fill="black" />
                                        <rect x={11} y={14} width={7} height={2} rx={1} transform="rotate(-90 11 14)" fill="black" />
                                        <rect x={11} y={17} width={2} height={2} rx={1} transform="rotate(-90 11 17)" fill="black" />
                                        </svg>
                                    </span>
                                <div className="text-gray-700 fw-bold fs-6">Important note! The last email that we able to migrated start from 30 December at 15 PM (WIB) and there is a slight delay on emails that we receive on December 31 before 10 AM (WIB).</div>
                                </div>
                            </div>
                            {/*end::Info*/}
                            {/*start::Info*/}
                            <div class="py-5">
                                <div className="d-flex align-items-center rounded py-5 px-5 bg-light-warning border border-warning border-3 border-dashed">
                                    <span className="svg-icon svg-icon-3x svg-icon-warning me-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={10} fill="black" />
                                        <rect x={11} y={14} width={7} height={2} rx={1} transform="rotate(-90 11 14)" fill="black" />
                                        <rect x={11} y={17} width={2} height={2} rx={1} transform="rotate(-90 11 17)" fill="black" />
                                        </svg>
                                    </span>
                                <div className="text-gray-700 fw-bold fs-6">Important note! Storage for each user has 50 GB. Please make sure you login to your outlook account to check your storage. You might need to delete some email.</div>
                                </div>
                            </div>
                            {/*end::Info*/}
                            {/*end::Content*/}
                            <div class="separator my-10"></div>
                            
                            {/*start::Content*/}
                            <h2 className="anchor fw-bolder mb-2" id="office-exchange2">
                                <a href="#office-exchange3" />1.2. Initialize Exchange:
                            </h2>
                            <div className="py-10">
                            {/*begin::List*/}
                            <ol className="py-0 m-0">
                                <li>Verify Domain</li>
                                <li>Assign User License</li>
                                <li>Obtain the FQDN of the IMAP server</li>
                                <li>Configure the firewall to allow IMAP connections</li>
                                <li className="py-3">In terminal, run the following command to create 
                                <div className="py-5">
                                    {/*begin::Highlight*/}
                                    <div className="highlight">
                                    <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                                    <div className="highlight-code">
                                        <pre className=" language-bash" tabIndex={0}><code className=" language-bash">Test-MigrationServerAvailability -IMAP -RemoteServer FQDN of IMAP server -Port 143 or 993 -Security None, Ssl, or Tls</code></pre>
                                    </div>
                                    </div>
                                    {/*end::Highlight*/}
                                </div>
                                </li>
                                <li>Prepare a CSV file for an IMAP migration batch</li>
                                <li className="py-3">Create an IMAP migration endpoint 
                                <div className="py-5">
                                    {/*begin::Highlight*/}
                                    <div className="highlight">
                                    <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                                    <div className="highlight-code">
                                        <pre className=" language-bash" tabIndex={0}><code className=" language-bash"><span className="token builtin class-name">Kss-MigrationEndpoint</span> -IMAP -Name IMAPEndpoint -RemoteServer imap.gmail.com -Port 993 -Security Ssl</code></pre>
                                    </div>
                                    </div>
                                    {/*end::Highlight*/}
                                </div>
                                <div className="py-5">
                                    <span>Or add parameters to specify concurrent migrations</span>
                                    {/*begin::Highlight*/}
                                    <div className="highlight">
                                    <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                                    <div className="highlight-code">
                                        <pre className=" language-bash" tabIndex={0}><code className=" language-bash"><span className="token builtin class-name">Kss-MigrationEndpoint</span> -IMAP -Name IMAPEndpoint -RemoteServer imap.gmail.com -Port 143 -Security Tls -MaxConcurrentMigrations 50 -MaxConcurrentIncrementalSyncs 25</code></pre>
                                    </div>
                                    </div>
                                    {/*end::Highlight*/}
                                </div>
                                <div className="py-5">
                                    <span>Verify it worked</span>
                                    {/*begin::Highlight*/}
                                    <div className="highlight">
                                    <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                                    <div className="highlight-code">
                                        <pre className=" language-bash" tabIndex={0}><code className=" language-bash"><span className="token builtin class-name">Get-MigrationEndpoint</span> IMAPEndpoint | Format-List EndpointType,RemoteServer,Port,Security,Max*</code></pre>
                                    </div>
                                    </div>
                                    {/*end::Highlight*/}
                                </div>
                                </li>
                                <li className="py-3">Create and start an IMAP migration batch 
                                <div className="py-5">
                                    {/*begin::Highlight*/}
                                    <div className="highlight">
                                    <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                                    <div className="highlight-code">
                                        <pre className=" language-bash" tabIndex={0}><code className=" language-bash"><span className="token builtin class-name">New-MigrationBatch</span> New-MigrationBatch -Name IMAPBatch1 -SourceEndpoint IMAPEndpoint -CSVData ([System.IO.File]::ReadAllBytes("C:\Users\Administrator\Desktop\IMAPmigration_1.csv")) -AutoStart</code></pre>
                                    </div>
                                    </div>
                                    {/*end::Highlight*/}
                                </div>
                                <div className="py-5">
                                    <span>Verify it worked</span>
                                    {/*begin::Highlight*/}
                                    <div className="highlight">
                                    <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                                    <div className="highlight-code">
                                        <pre className=" language-bash" tabIndex={0}><code className=" language-bash"><span className="token builtin class-name">Get-MigrationBatch</span> -Identity IMAPBatch1 | Format-List Status</code></pre>
                                    </div>
                                    </div>
                                    {/*end::Highlight*/}
                                </div>
                                </li>
                                <li>Route email to Microsoft 365. By moving the MX record, you can also turn off your old email system when you're ready. <br/>It can take up to 72 hours for the email systems of your customers and partners to recognize the changed MX record. Wait at least 72 hours before you proceed to the next task</li>
                                <li className="py-3">Delete IMAP migration batch
                                <div className="py-5">
                                    {/*begin::Highlight*/}
                                    <div className="highlight">
                                    <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                                    <div className="highlight-code">
                                        <pre className=" language-bash" tabIndex={0}><code className=" language-bash">Remove-MigrationBatch -Identity IMAPBatch1</code></pre>
                                    </div>
                                    </div>
                                    {/*end::Highlight*/}
                                </div>
                                <div className="py-5">
                                    <span>Verify it worked</span>
                                    {/*begin::Highlight*/}
                                    <div className="highlight">
                                    <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                                    <div className="highlight-code">
                                        <pre className=" language-bash" tabIndex={0}><code className=" language-bash"><span className="token builtin class-name">Get-MigrationBatch</span> IMAPBatch1"</code></pre>
                                    </div>
                                    </div>
                                    {/*end::Highlight*/}
                                </div>
                                </li>
                            </ol>
                            {/*end::List*/}
                            </div>
                            {/*end::Content*/}
                        </div>
                        {/*end::Block*/}
                    </div>

                    </div>
                    {/*end::Card Body*/}
                </div>
                {/*end::Card*/}
            </div>
        )
    }
}
