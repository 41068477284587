import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export default class sidebar extends Component {
    render() {
        return (
            <div id="kt_docs_aside" className="docs-aside" data-kt-drawer="true" data-kt-drawer-name="aside" data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_docs_aside_toggle">
            {/*begin::Logo*/}
                <div className="docs-aside-logo flex-column-auto h-75px" id="kt_docs_aside_logo">
                    {/*begin::Link*/}
                        <img alt="" src="../@space/kss-logo.png" className="h-35px" />
                    {/*end::Link*/}
                </div>
                {/*end::Logo*/}
                {/*begin::Menu*/}
                <div className="docs-aside-menu flex-column-fluid">
                    {/*begin::Aside Menu*/}
                    <div className="hover-scroll-overlay-y mt-5 mb-5 mt-lg-0 mb-lg-5 pe-lg-n2 me-lg-2" id="kt_docs_aside_menu_wrapper" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_docs_aside_logo" data-kt-scroll-wrappers="#kt_docs_aside_menu" data-kt-scroll-offset="10px">
                    {/*begin::Menu*/}
                    <div className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500" id="#kt_docs_aside_menu" data-kt-menu="true">
                        <div className="menu-item">
                            <h4 className="menu-content text-muted mb-0 fs-7 text-uppercase">Getting Started</h4>
                        </div>
                        {/*list::Menu*/}
                        <Link to='/' ClassName="active">
                        <div className="menu-item">
                            <a className="menu-link py-2" href="">
                                <span className="menu-title">Overview</span>
                            </a>
                        </div>
                        </Link>
                        {/*list::Menu*/}
                        <div className="menu-item menu-accordion show">
                        <span className="menu-link py-2">
                            <span className="menu-title">IaaS</span>
                            <span className="menu-arrow" />
                        </span>
                        <div className="menu-sub menu-sub-accordion">
                            <div className="menu-item">
                                <Link className="menu-link py-2" to='linode'>
                                    <span className="menu-bullet">
                                    <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">Linode</span>
                                    <span className="badge badge-exclusive badge-light-warning fw-bold fs-9 px-2 py-1 ms-1">Backup in Progress</span>
                                </Link>
                            </div>
                            <div className="menu-item">
                                <Link className="menu-link py-2" to='gcp'>
                                    <span className="menu-bullet">
                                    <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">Google Cloud Platform</span>
                                </Link>
                            </div>
                        </div>
                        </div>
                        
                        {/*list::Menu*/}
                        <div className="menu-item menu-accordion show">
                        <span className="menu-link py-2">
                            <span className="menu-title">Saas</span>
                            <span className="menu-arrow" />
                        </span>
                        <div className="menu-sub menu-sub-accordion">

                            <div className="menu-item">
                                <Link className="menu-link py-2" to='/sap'>
                                    <span className="menu-bullet">
                                    <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">SAP</span>
                                </Link>
                            </div>

                            <div className="menu-item">
                                <Link className="menu-link py-2" to='/office365'>
                                    <span className="menu-bullet">
                                    <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">Microsoft</span>
                                    <span className="badge badge-exclusive badge-light-danger fw-bold fs-9 px-2 py-1 ms-1">Important</span>
                                </Link>
                            </div>
                            
                            <div className="menu-item">
                                <Link className="menu-link py-2" to='/google-workspace'>
                                    <span className="menu-bullet">
                                    <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">Google Workspace</span>
                                </Link>
                            </div>

                            <div className="menu-item">
                                <Link className="menu-link py-2" to='/accurate'>
                                    <span className="menu-bullet">
                                    <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">Accurate</span>
                                </Link>
                            </div>

                        </div>
                        </div>

                        <div className="menu-item">
                            <h4 className="menu-content text-muted mb-0 fs-7 text-uppercase">BASE</h4>
                        </div>

                        {/*list::Menu*/}
                        <div className="menu-item menu-accordion show">
                        <span className="menu-link py-2">
                            <span className="menu-title">Web Development</span>
                            <span className="menu-arrow" />
                        </span>
                        <div className="menu-sub menu-sub-accordion">
                            <div className="menu-item">
                                <Link className="menu-link py-2" to='ci'>
                                    <span className="menu-bullet">
                                    <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">CI</span>
                                </Link>
                            </div>
                            <div className="menu-item">
                                <Link className="menu-link py-2" to='react'>
                                    <span className="menu-bullet">
                                    <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">React JS</span>
                                </Link>
                            </div>
                            <div className="menu-item">
                                <Link className="menu-link py-2" to='weblist'>
                                    <span className="menu-bullet">
                                    <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">Web List</span>
                                </Link>
                            </div>
                            <div className="menu-item">
                                <Link className="menu-link py-2" to='htaccess'>
                                    <span className="menu-bullet">
                                    <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">.htaccess</span>
                                </Link>
                            </div>
                            <div className="menu-item">
                                <Link className="menu-link py-2" to='seo'>
                                    <span className="menu-bullet">
                                    <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">Advanced SEO</span>
                                </Link>
                            </div>
                            <div className="menu-item">
                                <Link className="menu-link py-2" to='security'>
                                    <span className="menu-bullet">
                                    <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">Security Testing</span>
                                </Link>
                            </div>
                        </div>
                        </div>

                        {/*list::Menu*/}
                        <div className="menu-item menu-accordion show">
                        <span className="menu-link py-2">
                            <span className="menu-title">DevOps</span>
                            <span className="menu-arrow" />
                        </span>
                        <div className="menu-sub menu-sub-accordion">
                            <div className="menu-item">
                                <Link className="menu-link py-2" to='technical'>
                                    <span className="menu-bullet">
                                    <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">Technical</span>
                                </Link>
                            </div>
                            <div className="menu-item">
                                <Link className="menu-link py-2" to='troubleshooting'>
                                    <span className="menu-bullet">
                                    <span className="bullet bullet-dot" />
                                    </span>
                                    <span className="menu-title">Troubleshoting</span>
                                </Link>
                            </div>
                        </div>
                        </div>

                    </div>
                    {/*end::Menu*/}
                    </div>
                </div>
            {/*end::Menu*/}
            </div>
        )
    }
}
