import React from 'react'

function Linode() {
    return (
        <div className="container" id="kt_docs_content_container">
        {/*begin::Card*/}
        <div className="card card-docs mb-2">
            {/*begin::Card Body*/}
            <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
            {/*begin::Section*/}
            <div className="pb-10">
                {/*begin::Heading*/}
                <h1 className="anchor fw-bolder mb-2" id="linode1">
                <a href="#linode1" /><img alt="" src="../@space/media/services/linode-logo.png" className="h-35px" />
                &nbsp;<span className="svg-icon svg-icon-primary svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                    <path d="M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z" fill="#00A3FF" />
                    <path className="permanent" d="M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z" fill="white" />
                </svg></span>
                </h1>
                {/*end::Heading*/}

                {/*begin::Block*/}
                <div className="pt-5">
                    <div class="separator my-10"></div>
                    {/*start::Content*/}
                    <h2 className="anchor fw-bolder mb-2" id="linode2">
                        <a href="#linode2" />1. Cloud Server Deployment
                    </h2>
                    <div className="pb-10">
                        {/*begin::Block*/}
                        <div className="py-5">The cloud server deployment page shows a number of options available for customizing a new cloud server. In this guide, we use some helpful information to deploy with variety of server configurations. We use basic macOS & Linux command <code>wget</code> and <code>curl</code> to collect resource.</div>
                        {/*end::Block*/}
                    </div>
                    {/*end::Content*/}
                    <div class="separator my-10"></div>
                    {/*start::Content*/}
                    <h2 className="anchor fw-bolder mb-2" id="linode">
                        <a href="#linode" />1.2 List Cloud Server Deployment
                    </h2>
                    <div className="pt-10">
                    {/*begin::Table*/}
                    <div className="py-2">
                        {/*begin::Table wrapper*/}
                        <div className="table-responsive border rounded">
                        {/*begin::Table*/}
                        <table className="table table-striped mb-0 g-6">
                            {/*begin::Head*/}
                            <thead>
                            <tr className="fs-4 fw-bolder p-6">
                                <th className="min-w-400px">FQDN Name</th>
                                <th className="min-w-500px">Status</th>
                            </tr>
                            </thead>
                            {/*end::Head*/}
                            {/*begin::Body*/}
                            <tbody>
                            <tr>
                                <td><code>*-computing.*.com</code></td>
                                <td><code>Production</code>
                                <div className="pt-3">
                                    {/*begin::Highlight*/}
                                    <div className="highlight">
                                    <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                                    <div className="highlight-code">
                                        <pre className=" language-bash" tabIndex={0}><code className=" language-bash">curl -I *.com <br/>HTTP/1.1 301 Moved Permanently</code></pre>
                                    </div>
                                    </div>
                                    {/*end::Highlight*/}
                                </div></td>
                            </tr>
                            </tbody>
                            {/*end::Body*/}
                        </table>
                        </div>
                    </div>
                    {/*end::Table*/}
                    </div>

                    {/*end::Content*/}
                    <div class="separator my-10"></div>

                   {/*start::Content*/}
                   <h2 className="anchor fw-bolder mb-2" id="linode3">
                        <a href="#linode3" />2. Create LAMP Stack Environments
                    </h2>
                    <div className="py-10">
                    {/*begin::List*/}
                    <ul className="py-0 m-0">
                        <li className="py-3">We use centOS for LAMP Stack Environments
                        <div className="py-5">
                            {/*begin::Highlight*/}
                            <div className="highlight">
                            <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                            <div className="highlight-code">
                                <pre className=" language-bash" tabIndex={0}><code className=" language-bash"><span className="token builtin class-name">mkdir newsite <br/>cd newsite</span></code></pre>
                            </div>
                            </div>
                            {/*end::Highlight*/}
                            <br/>
                            {/*begin::Highlight*/}
                            <div className="highlight">
                            <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                            <div className="highlight-code">
                                <pre className=" language-bash" tabIndex={0}><code className=" language-bash"><span className="token builtin class-name">wget</span> ftp://ftp.pbone.net/mirror/dag.wieers.com/packages/apt/apt-0.5.15cnc6-3.1.el3.dag.i386.rpm rpm -Uvh apt*</code></pre>
                            </div>
                            </div>
                            {/*end::Highlight*/}
                            <br/>
                            {/*begin::Highlight*/}
                            <div className="highlight">
                            <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                            <div className="highlight-code">
                                <pre className=" language-bash" tabIndex={0}><code className=" language-bash"><span className="token builtin class-name">apt-get update</span></code></pre>
                            </div>
                            </div>
                            {/*end::Highlight*/}
                            <br/>
                            {/*begin::Highlight*/}
                            <div className="highlight">
                            <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                            <div className="highlight-code">
                                <pre className=" language-bash" tabIndex={0}><code className=" language-bash"><span className="token builtin class-name">apt-get upgrade</span></code></pre>
                            </div>
                            </div>
                            {/*end::Highlight*/}
                            <br/>
                            {/*begin::Highlight*/}
                            <div className="highlight">
                            <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                            <div className="highlight-code">
                                <pre className=" language-bash" tabIndex={0}><code className=" language-bash"><span className="token builtin class-name">echo mydomain {'>'}/etc/hostname </span></code></pre>
                            </div>
                            </div>
                            {/*end::Highlight*/}
                            <br/>
                            {/*begin::Highlight*/}
                            <div className="highlight">
                            <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                            <div className="highlight-code">
                                <pre className=" language-bash" tabIndex={0}><code className=" language-bash"><span className="token builtin class-name">/bin/hostname -F /etc/hostname </span></code></pre>
                            </div>
                            </div>
                            {/*end::Highlight*/}
                            <br/>
                            {/*begin::Highlight*/}
                            <div className="highlight">
                            <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                            <div className="highlight-code">
                                <pre className=" language-bash" tabIndex={0}><code className=" language-bash">
                                <span className="token builtin class-name">wget</span> ftp://ftp.freshrpms.net/pub/dag/packages/perl-Net-SSLeay/perl-Net-SSLeay-1.23-0.dag.rhel3.i386.rpm
                                <br/>
                                rpm -Uvh perl*
                                <br/>
                                perl -e 'use Net::SSLeay'
                                <br/>
                                <span className="token builtin class-name">wget</span> http://aleron.dl.sourceforge.net/sourceforge/webadmin/webmin-1.150-1.noarch.rpm
                                <br/>
                                rpm -Uvh webmin*
                                </code></pre>
                            </div>
                            </div>
                            {/*end::Highlight*/}
                        </div>
                        </li>
                    </ul>
                    {/*end::List*/}
                    </div>
                    {/*end::Content*/}

                   {/*start::Content*/}
                   <h2 className="anchor fw-bolder mb-2" id="linode4">
                        <a href="#linode4" />3. Control Panel for app deployment in the cloud.
                    </h2>
                    <div className="py-10">
                    {/*begin::List*/}
                    <ul className="py-0 m-0">
                    <li className="py-3">For cPanel/WHM
                        <div className="py-5">
                            {/*begin::Highlight*/}
                            <div className="highlight">
                            <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                            <div className="highlight-code">
                                <pre className=" language-bash" tabIndex={0}><code className=" language-bash"><span className="token builtin class-name">cd /home</span> && curl -o latest -L https://securedownloads.cpanel.net/latest && sh latest</code></pre>
                            </div>
                            </div>
                            {/*end::Highlight*/}
                        </div>
                        </li>
                        <li className="py-3">For Plesk
                        <div className="py-5">
                            {/*begin::Highlight*/}
                            <div className="highlight">
                            <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                            <div className="highlight-code">
                                <pre className=" language-bash" tabIndex={0}><code className=" language-bash"><span className="token builtin class-name">sh curl</span> https://autoinstall.plesk.com/one-click-installer || wget -O - https://autoinstall.plesk.com/one-click-installer</code></pre>
                            </div>
                            </div>
                            {/*end::Highlight*/}
                        </div>
                        </li>
                    </ul>
                    {/*end::List*/}
                    </div>
                    {/*end::Content*/}
                    <div class="separator my-10"></div>
                    {/*start::Content*/}
                    <h2 className="anchor fw-bolder mb-2" id="linode5">
                        <a href="#linode5" />4. SSH Keys initialization for Maintenace & Data Portection
                    </h2>
                    <br/> 
                    <h3>Public Key Authentication on macOS And Linux.</h3>
                    {/*start::Info*/}
                    <div class="py-5">
                        {/*begin::List*/}
                        <ul className="py-0 m-0">
                            <li className="py-3">Create a new key pair.
                            <div className="py-5">
                                <div className="d-flex align-items-center rounded py-5 px-5 bg-light-danger">
                                    <span className="svg-icon svg-icon-3x svg-icon-danger me-5">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={10} fill="black" />
                                        <rect x={11} y={14} width={7} height={2} rx={1} transform="rotate(-90 11 14)" fill="black" />
                                        <rect x={11} y={17} width={2} height={2} rx={1} transform="rotate(-90 11 17)" fill="black" />
                                        </svg>
                                    </span>
                                <div className="text-gray-700 fw-bold fs-6">Caution <br/>This command will overwrite an existing RSA key pair, potentially locking you out of other systems.<br/>If you’ve already created a key pair, skip this step. To check for existing keys, run ls ~/.ssh/id_rsa*.<br/>If you accidentally lock yourself out of the SSH service on your Linode, you can still use the Lish console to login to your server. After you’ve logged in via Lish, update your authorized_keys file to use your new public key. This should re-establish normal SSH access.</div>
                                </div>
                                <br/>
                                {/*begin::Highlight*/}
                                <div className="highlight">
                                <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                                <div className="highlight-code">
                                    <pre className=" language-bash" tabIndex={0}><code className=" language-bash">ssh-keygen -b 4096</code></pre>
                                </div>
                                </div>
                                {/*end::Highlight*/}
                            </div>
                            </li>
                            <li className="py-3">Upload Public Key .
                            <div className="py-5">
                                {/*begin::Highlight*/}
                                <div className="highlight">
                                <button className="highlight-copy btn" data-bs-toggle="tooltip" title data-bs-original-title="Copy code">copy</button>
                                <div className="highlight-code">
                                    <pre className=" language-bash" tabIndex={0}><code className=" language-bash">ssh-copy-id your_username@IPCLOUDSERVER</code></pre>
                                </div>
                                </div>
                                {/*end::Highlight*/}
                            </div>
                            </li>
                        </ul>
                        {/*end::List*/}

                    </div>
                    {/*end::Info*/}
                    {/*end::Content*/}
                </div>
                {/*end::Block*/}
            </div>
            </div>
            {/*end::Card Body*/}
        </div>
        {/*end::Card*/}
        </div>
    )
}

export default Linode;