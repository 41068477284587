import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Sidebar from './Frontend/Component/Sidebar';
import Header from './Frontend/Component/Header';

import Linode from './Frontend/Iaas/Linode';
import Gcloud from './Frontend/Iaas/Gcloud';

import Sap from './Frontend/Saas/Sap';
import Office365 from './Frontend/Saas/Office365';
import Gsuite from './Frontend/Saas/Gsuite';
import Accurate from './Frontend/Saas/Accurate';

import Ci from './Frontend/Webdev/Ci';
import Dev from './Frontend/Webdev/Dev';
import Htaccess from './Frontend/Webdev/Htaccess';
import Weblist from './Frontend/Webdev/Weblist';
import Rsecurity from './Frontend/Webdev/RSecurity';
import Seo from './Frontend/Webdev/Seo';

import Technical from './Frontend/Devops/Technical';
import Troubleshoot from './Frontend/Devops/Troubleshoot';

import Footer from './Frontend/Component/Footer';

function App() {
  return (
    <Router>
    {/* router */}
    <div className="d-flex flex-column flex-root">
        <div className="docs-page d-flex flex-row flex-column-fluid">
          <Sidebar />
          {/* sidebar */}
            <div className="docs-wrapper d-flex flex-column flex-row-fluid" id="kt_docs_wrapper">
              <Header />
              {/* header */}
                <div className="docs-content d-flex flex-column flex-column-fluid" id="kt_docs_content">
                <Routes>
                  <Route path="/" exact element={<Home/>} />
                  <Route path="/linode" element={<Linode/>} />
                  <Route path="/gcp" element={<Gcloud/>} />
                  <Route path="/sap" element={<Sap/>} />
                  <Route path="/office365" element={<Office365/>} />
                  <Route path="/google-workspace" element={<Gsuite/>} />
                  <Route path="/accurate" element={<Accurate/>} />
                  <Route path="/ci" element={<Ci/>} />
                  <Route path="/react" element={<Dev/>} />
                  <Route path="/htaccess" element={<Htaccess/>} />
                  <Route path="/security" element={<Rsecurity/>} />
                  <Route path="/weblist" element={<Weblist/>} />
                  <Route path="/seo" element={<Seo/>} />
                  <Route path="/technical" element={<Technical/>} />
                  <Route path="/troubleshooting" element={<Troubleshoot/>} />
                </Routes>
                {/* content */}
                </div>
                <Footer />
            </div>
        </div>
    </div>
    </Router>
  );
}

const Home = () => (
    <div className="container" id="kt_docs_content_container">
    {/*begin::Card*/}
    <div className="card card-docs mb-2">
        {/*begin::Card Body*/}
        <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
        {/*begin::Section*/}
        <div className="px-md-10 pt-md-10 pb-md-5">
            {/*begin::Block*/}
            <div className="text-center mb-20">
            <h1 className="fs-2tx fw-bolder mb-4">Welcome to KSS Docs
                <span className="d-inline-block position-relative ms-2">
                <span className="d-inline-block mb-2">Build, Release, & Improve is Fun :)</span>
                <span className="d-inline-block position-absolute h-8px bottom-0 end-0 start-0 bg-primary translate rounded" />
                </span></h1>
            <div className="fw-bold fs-2 text-gray-500 mb-10">This page is used for making documentations about IT Infrastructure include Internet of Things, Web Dev, Technology Integration, User Experience in the existing system and More. Once it can be answer and solution every guides or tasks will be marked as Verified. </div>
            </div>
            {/*end::Block*/}
            {/*begin::Row*/}
            <div className="row g-0">
            {/*begin::Col*/}
            <div className="col-md-6 mb-10">
                <div className="bg-light bg-opacity-50 rounded-3 p-10 mx-md-5 h-md-100">
                <div className="d-flex flex-center w-60px h-60px rounded-3 bg-light-success bg-opacity-90 mb-10">
                    {/*begin::Svg Icon | path: icons/duotune/coding/cod001.svg*/}
                    <span className="svg-icon svg-icon-primary svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                        <path opacity="0.3" d="M19.0687 17.9688H11.0687C10.4687 17.9688 10.0687 18.3687 10.0687 18.9688V19.9688C10.0687 20.5687 10.4687 20.9688 11.0687 20.9688H19.0687C19.6687 20.9688 20.0687 20.5687 20.0687 19.9688V18.9688C20.0687 18.3687 19.6687 17.9688 19.0687 17.9688Z" fill="black" />
                        <path d="M4.06875 17.9688C3.86875 17.9688 3.66874 17.8688 3.46874 17.7688C2.96874 17.4688 2.86875 16.8688 3.16875 16.3688L6.76874 10.9688L3.16875 5.56876C2.86875 5.06876 2.96874 4.46873 3.46874 4.16873C3.96874 3.86873 4.56875 3.96878 4.86875 4.46878L8.86875 10.4688C9.06875 10.7688 9.06875 11.2688 8.86875 11.5688L4.86875 17.5688C4.66875 17.7688 4.36875 17.9688 4.06875 17.9688Z" fill="black" />
                    </svg></span>

                    {/*end::Svg Icon*/}
                </div>
                <h1 className="mb-5">IaaS</h1>
                <div className="fs-4 text-gray-600 py-3"><span className="fw-bolder text-gray-700">Infrastructure as a service (IaaS)</span> is a form of cloud computing that provides virtualized computing resources over the internet. IaaS is one of the three main categories of cloud computing services, alongside with software as a service (SaaS) and platform as a service (PaaS).</div>
                
                <Link className="btn btn-lg btn-flex btn-link btn-color-primary" to='linode'>Linode
                    <span className="svg-icon ms-2 svg-icon-3">
                        <i className='fas fa-arrow-right'></i>
                    </span>
                </Link>
                <Link className="btn btn-lg btn-flex btn-link btn-color-primary" to='gcp'>Google Cloud Platform 
                    <span className="svg-icon ms-2 svg-icon-3">
                        <i className='fas fa-arrow-right'></i>
                    </span>
                </Link>

                </div>
            </div>
            {/*end::Col*/}
            {/*begin::Col*/}
            <div className="col-md-6 mb-10">
                <div className="bg-light bg-opacity-50 rounded-3 p-10 mx-md-5 h-md-100">
                <div className="d-flex flex-center w-60px h-60px rounded-3 bg-light-success bg-opacity-90 mb-10">
                    {/*begin::Svg Icon | path: icons/duotune/art/art006.svg*/}
                    <span className="svg-icon svg-icon-success svg-icon-2hx"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                        <path d="M8 22C7.4 22 7 21.6 7 21V9C7 8.4 7.4 8 8 8C8.6 8 9 8.4 9 9V21C9 21.6 8.6 22 8 22Z" fill="black" />
                        <path opacity="0.3" d="M4 15C3.4 15 3 14.6 3 14V6C3 5.4 3.4 5 4 5C4.6 5 5 5.4 5 6V14C5 14.6 4.6 15 4 15ZM13 19V3C13 2.4 12.6 2 12 2C11.4 2 11 2.4 11 3V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19ZM17 16V5C17 4.4 16.6 4 16 4C15.4 4 15 4.4 15 5V16C15 16.6 15.4 17 16 17C16.6 17 17 16.6 17 16ZM21 18V10C21 9.4 20.6 9 20 9C19.4 9 19 9.4 19 10V18C19 18.6 19.4 19 20 19C20.6 19 21 18.6 21 18Z" fill="black" />
                    </svg></span>
                    {/*end::Svg Icon*/}
                </div>
                <h1 className="mb-5">SaaS</h1>
                <div className="fs-4 text-gray-600 py-3" style={{textAlign: 'justify'}}><span className="fw-bolder text-gray-700">Software as a Service (SaaS)</span> is a software licensing model, which allows access to software a subscription basis using external servers. SaaS allows each user to access programs via the Internet, instead of having to install the software on the user's computer.</div>
                <Link className="btn btn-lg btn-flex btn-link btn-color-success" to='sap'>SAP
                    <span className="svg-icon ms-2 svg-icon-3">
                        <i className='fas fa-arrow-right'></i>
                    </span>
                </Link>
                <Link className="btn btn-lg btn-flex btn-link btn-color-success" to='office365'>Microsoft
                    <span className="svg-icon ms-2 svg-icon-3">
                        <i className='fas fa-arrow-right'></i>
                    </span>
                </Link>
                <Link className="btn btn-lg btn-flex btn-link btn-color-success" to='google-workspace'>Google Workspace
                    <span className="svg-icon ms-2 svg-icon-3">
                        <i className='fas fa-arrow-right'></i>
                    </span>
                </Link>
                <Link className="btn btn-lg btn-flex btn-link btn-color-success" to='accurate'>Accurate
                    <span className="svg-icon ms-2 svg-icon-3">
                        <i className='fas fa-arrow-right'></i>
                    </span>
                </Link>
                </div>
            </div>
            {/*end::Col*/}
            {/*begin::Col*/}
            <div className="col-md-6 mb-10">
                <div className="bg-light bg-opacity-50 rounded-3 p-10 mx-md-5 h-md-100">
                <div className="d-flex flex-center w-60px h-60px rounded-3 bg-light-info bg-opacity-90 mb-10">
                    {/*begin::Svg Icon | path: icons/duotune/coding/cod003.svg*/}
                    <span className="svg-icon svg-icon-info svg-icon-3x">
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                        <path d="M16.95 18.9688C16.75 18.9688 16.55 18.8688 16.35 18.7688C15.85 18.4688 15.75 17.8688 16.05 17.3688L19.65 11.9688L16.05 6.56876C15.75 6.06876 15.85 5.46873 16.35 5.16873C16.85 4.86873 17.45 4.96878 17.75 5.46878L21.75 11.4688C21.95 11.7688 21.95 12.2688 21.75 12.5688L17.75 18.5688C17.55 18.7688 17.25 18.9688 16.95 18.9688ZM7.55001 18.7688C8.05001 18.4688 8.15 17.8688 7.85 17.3688L4.25001 11.9688L7.85 6.56876C8.15 6.06876 8.05001 5.46873 7.55001 5.16873C7.05001 4.86873 6.45 4.96878 6.15 5.46878L2.15 11.4688C1.95 11.7688 1.95 12.2688 2.15 12.5688L6.15 18.5688C6.35 18.8688 6.65 18.9688 6.95 18.9688C7.15 18.9688 7.35001 18.8688 7.55001 18.7688Z" fill="black" />
                        <path opacity="0.3" d="M10.45 18.9687C10.35 18.9687 10.25 18.9687 10.25 18.9687C9.75 18.8687 9.35 18.2688 9.55 17.7688L12.55 5.76878C12.65 5.26878 13.25 4.8687 13.75 5.0687C14.25 5.1687 14.65 5.76878 14.45 6.26878L11.45 18.2688C11.35 18.6688 10.85 18.9687 10.45 18.9687Z" fill="black" />
                    </svg>
                    </span>
                    {/*end::Svg Icon*/}
                </div>
                <h1 className="mb-5">Web Dev</h1>
                <div className="fs-4 text-gray-600 py-3" style={{textAlign: 'justify'}}><span className="fw-bolder text-gray-700">Web Development</span> is to understanding how to use the web's modern capabilities. Structure, Lighweight, Responsive across device and browser is used in all types of modern web development. Take advantage of the latest modern technologies to build amazing web experiences for everyone.</div>
                <Link className="btn btn-lg btn-flex btn-link btn-color-info" to='ci'>CI
                    <span className="svg-icon ms-2 svg-icon-3">
                        <i className='fas fa-arrow-right'></i>
                    </span>
                </Link>
                <Link className="btn btn-lg btn-flex btn-link btn-color-info" to='react'>React JS
                    <span className="svg-icon ms-2 svg-icon-3">
                        <i className='fas fa-arrow-right'></i>
                    </span>
                </Link>
                <Link className="btn btn-lg btn-flex btn-link btn-color-info" to='security'>Security Testing
                    <span className="svg-icon ms-2 svg-icon-3">
                        <i className='fas fa-arrow-right'></i>
                    </span>
                </Link>
                <Link className="btn btn-lg btn-flex btn-link btn-color-info" to='weblist'>Web List
                    <span className="svg-icon ms-2 svg-icon-3">
                        <i className='fas fa-arrow-right'></i>
                    </span>
                </Link>
                <Link className="btn btn-lg btn-flex btn-link btn-color-info" to='htaccess'>Htaccess
                    <span className="svg-icon ms-2 svg-icon-3">
                        <i className='fas fa-arrow-right'></i>
                    </span>
                </Link>
                </div>
            </div>
            {/*end::Col*/}
            {/*begin::Col*/}
            <div className="col-md-6 mb-10">
                <div className="bg-light bg-opacity-50 rounded-3 p-10 mx-md-5 h-md-100">
                <div className="d-flex flex-center w-60px h-60px rounded-3 bg-light-warning bg-opacity-90 mb-10">
                    {/*begin::Svg Icon | path: icons/duotune/abstract/abs026.svg*/}
                    <span className="svg-icon svg-icon-warning svg-icon-3x">
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                        <path opacity="0.3" d="M7 20.5L2 17.6V11.8L7 8.90002L12 11.8V17.6L7 20.5ZM21 20.8V18.5L19 17.3L17 18.5V20.8L19 22L21 20.8Z" fill="black" />
                        <path d="M22 14.1V6L15 2L8 6V14.1L15 18.2L22 14.1Z" fill="black" />
                    </svg>
                    </span>
                    {/*end::Svg Icon*/}
                </div>
                <h1 className="mb-5">DevOps</h1>
                <div className="fs-4 text-gray-600 py-3" style={{textAlign: 'justify'}}>DevOps is a set of practices that combines IT <span className="fw-bolder text-gray-700">Development</span> and IT <span className="fw-bolder text-gray-700">Operations</span>. It aims to shorten the systems development life cycle and provide continuous delivery with high quality. The goal of DevOps is to merge daily tasks involved in the development, quality control, deployment, and integration into a single, continuous set of processes.</div>
                <Link className="btn btn-lg btn-flex btn-link btn-color-warning" to='technical'>Techincal
                    <span className="svg-icon ms-2 svg-icon-3">
                        <i className='fas fa-arrow-right'></i>
                    </span>
                </Link>
                <Link className="btn btn-lg btn-flex btn-link btn-color-warning" to='troubleshooting'>Troubleshooting
                    <span className="svg-icon ms-2 svg-icon-3">
                        <i className='fas fa-arrow-right'></i>
                    </span>
                </Link>
                </div>
            </div>
            {/*end::Col*/}
            </div>
            {/*end::Row*/}
        </div>
        {/*end::Section*/}
        </div>
        {/*end::Card Body*/}
    </div>
    {/*end::Card*/}
    </div>
)

export default App;
