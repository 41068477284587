import React, { Component } from 'react'

export default class Ci extends Component {
    render() {
        return (
            <div className="container" id="kt_docs_content_container">
                {/*begin::Card*/}
                <div className="card card-docs mb-2">
                    {/*begin::Card Body*/}
                    <div className="card-body fs-6 py-15 px-10 py-lg-15 px-lg-15 text-gray-700">
                    {/*begin::Section*/}
                    <div className="pb-10">
                        {/*begin::Heading*/}
                        <h1 className="anchor fw-bolder mb-2" id="overview">
                        <a href="#overview" /><img alt="" src="../@space/media/services/codeigniter-logo.png" className="h-35px" /></h1>
                        {/*end::Heading*/}
                        {/*begin::Block*/}
                        <div className="pt-5">
                            <div class="separator my-10"></div>
                            {/*start::Info*/}
                            <div class="py-5">
                                <div className="d-flex align-items-center rounded py-5 px-5 bg-light-success">
                                <span className="svg-icon svg-icon-3x svg-icon-mute me-5"><svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.3" x={2} y={2} width={20} height={20} rx={10} fill="black" />
                                    <path d="M15.8054 11.639C15.6757 11.5093 15.5184 11.4445 15.3331 11.4445H15.111V10.1111C15.111 9.25927 14.8055 8.52784 14.1944 7.91672C13.5833 7.30557 12.8519 7 12 7C11.148 7 10.4165 7.30557 9.80547 7.9167C9.19432 8.52784 8.88885 9.25924 8.88885 10.1111V11.4445H8.66665C8.48153 11.4445 8.32408 11.5093 8.19444 11.639C8.0648 11.7685 8 11.926 8 12.1112V16.1113C8 16.2964 8.06482 16.4539 8.19444 16.5835C8.32408 16.7131 8.48153 16.7779 8.66665 16.7779H15.3333C15.5185 16.7779 15.6759 16.7131 15.8056 16.5835C15.9351 16.4539 16 16.2964 16 16.1113V12.1112C16.0001 11.926 15.9351 11.7686 15.8054 11.639ZM13.7777 11.4445H10.2222V10.1111C10.2222 9.6204 10.3958 9.20138 10.7431 8.85421C11.0903 8.507 11.5093 8.33343 12 8.33343C12.4909 8.33343 12.9097 8.50697 13.257 8.85421C13.6041 9.20135 13.7777 9.6204 13.7777 10.1111V11.4445Z" fill="black" />
                                </svg></span>
                                <div className="text-gray-700 fw-bold fs-6">Guide currently is not available for public.</div>
                                </div>
                            </div>
                            {/*end::Info*/}
                            {/*end::Content*/}
                        </div>
                        {/*end::Block*/}
                    </div>
                    </div>
                    {/*end::Card Body*/}
                </div>
                {/*end::Card*/}
            </div>
        )
    }
}
